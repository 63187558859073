import { Color, ColorGradient } from "@anderjason/color";
import { NumberUtil, Percent } from "@anderjason/util";
import { ElementStyle } from "@anderjason/web";
import { Actor } from "skytree";
import Img0 from "../../static/images/type0.jpg";
import Img1 from "../../static/images/type1.jpg";
import Img2 from "../../static/images/type2.jpg";
import Img3 from "../../static/images/type3.jpg";
import Img4 from "../../static/images/type4.jpg";
import Img5 from "../../static/images/type5.jpg";
import Img6 from "../../static/images/type6.jpg";
import Img7 from "../../static/images/type7.jpg";
import Img8 from "../../static/images/type8.jpg";
import Img9 from "../../static/images/type9.jpg";
import ImgA from "../../static/images/typeA.jpg";
import ImgB from "../../static/images/typeB.jpg";
import ImgC from "../../static/images/typeC.jpg";
import ImgD from "../../static/images/typeD.jpg";
import ImgE from "../../static/images/typeE.jpg";
import ImgF from "../../static/images/typeF.jpg";
import ImgG from "../../static/images/typeG.jpg";
import ImgH from "../../static/images/typeH.jpg";
import ImgI from "../../static/images/typeI.jpg";
import ImgJ from "../../static/images/typeJ.jpg";
import ImgK from "../../static/images/typeK.jpg";
import ImgL from "../../static/images/typeL.jpg";
import ImgM from "../../static/images/typeM.jpg";
import ImgN from "../../static/images/typeN.jpg";
import ImgO from "../../static/images/typeO.jpg";
import ImgP from "../../static/images/typeP.jpg";
import ImgQ from "../../static/images/typeQ.jpg";
import ImgR from "../../static/images/typeR.jpg";
import ImgS from "../../static/images/typeS.jpg";
import ImgT from "../../static/images/typeT.jpg";
import ImgU from "../../static/images/typeU.jpg";
import ImgV from "../../static/images/typeV.jpg";
import ImgW from "../../static/images/typeW.jpg";
import ImgX from "../../static/images/typeX.jpg";
import ImgY from "../../static/images/typeY.jpg";
import ImgZ from "../../static/images/typeZ.jpg";

export interface ImageLayoutProps {
  parentElement: HTMLElement;
}

const columnCount = 3;

const images = [
  ImgA,
  ImgB,
  ImgC,
  ImgD,
  ImgE,
  ImgF,
  ImgG,
  ImgH,
  ImgI,
  ImgJ,
  ImgK,
  ImgL,
  ImgM,
  ImgN,
  ImgO,
  ImgP,
  ImgQ,
  ImgR,
  ImgS,
  ImgT,
  ImgU,
  ImgV,
  ImgW,
  ImgX,
  ImgY,
  ImgZ,
  Img0,
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
];

export class ImageLayout extends Actor<ImageLayoutProps> {
  onActivate() {
    const imageCount = images.length;
    
    const outer = this.addActor(
      OuterStyle.toManagedElement({
        tagName: "div",
        parentElement: this.props.parentElement,
      })
    );

    const wrapper = this.addActor(
      WrapperStyle.toManagedElement({
        tagName: "div",
        parentElement: outer.element,
      })
    );

    for (let n = 0; n < columnCount; n++) {
      const column = this.addActor(
        ColumnStyle.toManagedElement({
          tagName: "div",
          parentElement: wrapper.element
        })
      );

      for (let i = 0; i < imageCount; i++) {
        if (i % columnCount === n) {
          const outer = this.addActor(
            OuterCell.toManagedElement({
              tagName: "div",
              parentElement: column.element,
            })
          );
    
          const width = Math.min(960, window.innerWidth);
          const square = width / columnCount;
          const edge = Math.round(NumberUtil.randomNumberGivenRange(square * 0.85, square * 1.1));
          outer.style.height = `${edge}px`;
    
          const inner = this.addActor(
            InnerCellStyle.toManagedElement({
              tagName: "img",
              parentElement: outer.element,
            })
          );
          this.cancelOnDeactivate(
            inner.addManagedEventListener("load", () => {
              inner.setModifier("isVisible", true);
            })
          );
          inner.element.loading = "lazy";
          inner.element.src = images[i];
        }
      }
    }
  }
}

const OuterStyle = ElementStyle.givenDefinition({
  elementDescription: "Outer",
  css: `
    padding: 10px;
  `
});

const WrapperStyle = ElementStyle.givenDefinition({
  elementDescription: "Wrapper",
  css: `
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  `,
});

const ColumnStyle = ElementStyle.givenDefinition({
  elementDescription: "Column",
  css: `
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  `
});

const OuterCell = ElementStyle.givenDefinition({
  elementDescription: "OuterCell",
  css: `
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0;
    width: 100%;
    background-color: rgba(255,255,255,0.1);
    border-radius: 4px;
  `,
});

const InnerCellStyle = ElementStyle.givenDefinition({
  elementDescription: "InnerCell",
  css: `
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 4px;
    transition: 1s ease opacity;
  `,
  modifiers: {
    isVisible: `
      opacity: 1;
    `
  }
});
